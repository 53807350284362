import { createAction, props } from '@ngrx/store';

import { BorrowerDashboardResponse } from '@views/borrower/borrower.types';

import { FetchConstantResponse } from './constants.types';

export const constantsFetchInvestorStart = createAction('CONSTANTS/FETCH_INVESTOR_START');
export const constantsFetchInvestorSuccess = createAction(
  'CONSTANTS/FETCH_INVESTOR_SUCCESS',
  props<{ payload: FetchConstantResponse }>(),
);
export const constantsFetchInvestorFail = createAction('CONSTANTS/FETCH_INVESTOR_FAIL');

export const constantsFetchBorrowerStart = createAction('CONSTANTS/FETCH_START');
export const constantsFetchBorrowerSuccess = createAction(
  'CONSTANTS/FETCH_BORROWER_CONSTANT_SUCCESS',
  props<{ payload: BorrowerDashboardResponse }>(),
);
export const constantsFetchBorrowerFail = createAction('CONSTANTS/FETCH_BORROWER_FAIL');
