import { Injectable } from '@angular/core';

import { Actions, createEffect } from '@ngrx/effects';

import { AuthService } from '@views/misc/auth/auth.service';

import * as actions from './bank-accounts.actions';
import { BankAccountsService } from './bank-accounts.service';

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private bankAccountsService: BankAccountsService,
    private authService: AuthService,
  ) {}

  fetchList$ = createEffect(() =>
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: () => this.bankAccountsService.fetchList(),
      errorMessage: 'Sorry, there has been an error fetching your bank accounts',
      startAction: actions.bankAccountsFetchListStart,
      successAction: actions.bankAccountsFetchListSuccess,
      failAction: actions.bankAccountsFetchListFail,
    }),
  );
}
