import { Injectable } from '@angular/core';

import { Actions, createEffect } from '@ngrx/effects';

import { AuthService } from '@views/misc/auth/auth.service';

import * as actions from './statistics.actions';
import { BrochureStatisticsService } from './statistics.service';

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private brochureStatisticsService: BrochureStatisticsService,
    private authService: AuthService,
  ) {}

  fetchBrochureStatistics$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: () => this.brochureStatisticsService.fetchStatisticsBrochure(),
      startAction: actions.statisticsBrochureFetchListStart,
      successAction: actions.statisticsBrochureFetchListSuccess,
      failAction: actions.statisticsBrochureFetchListFail,
    }),
  );

  fetchStatistics$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: () => this.brochureStatisticsService.fetchStatistics(),
      startAction: actions.statisticsFetchListStart,
      successAction: actions.statisticsFetchListSuccess,
      failAction: actions.statisticsFetchListFail,
    }),
  );

  fetchMarketplaceCustom$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: () => this.brochureStatisticsService.fetchMarketplaceCustomValues(),
      startAction: actions.statisticsMarketplaceCustomFetchStart,
      successAction: actions.statisticsMarketplaceCustomFetchSuccess,
      failAction: actions.statisticsMarketplaceCustomFetchFail,
    }),
  );
}
