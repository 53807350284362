import { createReducer, on } from '@ngrx/store';

import * as actions from './files.actions';
import * as entities from './files.entities';

export const reducer = createReducer(
  entities.initialState,
  on(actions.fileFetchListStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.fileFetchListSuccess, (state, { payload }) =>
    entities.adapter.setAll(payload.items, {
      ...state,
      metadata: payload.metadata,
      isLoading: false,
      isLoaded: true,
      isError: false,
    }),
  ),

  on(actions.fileFetchListFail, state => ({
    ...state,
    isLoading: false,
    isError: true,
  })),

  on(actions.fileResetList, state =>
    entities.adapter.removeAll({
      ...state,
      metadata: null,
    }),
  ),

  on(actions.fileAddItemStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.fileAddItemSuccess, (state, { payload }) =>
    entities.adapter.upsertOne(payload, {
      ...state,
      isLoading: false,
      isError: false,
    }),
  ),

  on(actions.fileAddItemFail, state => ({
    ...state,
    isLoading: false,
    isError: true,
  })),
);
