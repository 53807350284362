import { createReducer, on } from '@ngrx/store';

import * as actions from './navigation.actions';
import { NavigationState } from './navigation.types';

export const initialState: NavigationState = {
  backRoute: undefined,
  currentViewTitle: undefined,
};

export const reducer = createReducer(
  initialState,

  on(actions.navigationUpdateBackRoute, (state, { backRoute }) => ({
    ...state,
    backRoute,
  })),

  on(actions.navigationUpdateCurrentViewTitle, (state, { currentViewTitle }) => ({
    ...state,
    currentViewTitle,
  })),

  on(actions.navigationResetBackRoute, state => ({
    ...state,
    backRoute: initialState.backRoute,
  })),

  on(actions.navigationReset, () => ({
    ...initialState,
  })),
);
