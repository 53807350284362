import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PostLinkRequest, PostLinkResponse } from './link.types';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  constructor(private http: HttpClient) {}
  postLink(payload: PostLinkRequest): Observable<PostLinkResponse> {
    return this.http.post<PostLinkResponse>(`${environment.endpointApiTwo}/v2/link`, payload, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
}
