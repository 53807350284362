import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from '@views/misc/auth/auth.service';
import { StorageService } from '@views/misc/auth/storage.service';

import { FetchFilesRequest, FetchFilesResponse, UploadFileRequest, UploadFileResponse } from './files.types';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private authService: AuthService,
  ) {}

  fetchList(payload: FetchFilesRequest): Observable<FetchFilesResponse> {
    const userId = this.storageService.getUserId();
    const { pageIndex, pageSize } = payload;

    return this.http.get<FetchFilesResponse>(`${environment.endpointApiOne}/v1/files/users/${userId}`, {
      headers: this.authService.createAuthHeaders(),
      params: { pageIndex: `${pageIndex}`, pageSize: `${pageSize}` },
    });
  }

  uploadFile(payload: UploadFileRequest): Observable<UploadFileResponse> {
    const userId = this.storageService.getUserId();
    const { contentType, loanFileTypeId, isPrivate, file, filename, bankAccountId } = payload;

    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<UploadFileResponse>(`${environment.endpointApiOne}/v1/files/user/${userId}`, formData, {
      headers: this.authService.createAuthHeadersFile(filename),
      params: { contentType, loanFileTypeId: `${loanFileTypeId}`, isPrivate: `${isPrivate.toString()}`, bankAccountId },
    });
  }
}
