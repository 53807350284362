import { Injectable } from '@angular/core';

import { Actions, createEffect } from '@ngrx/effects';

import { AuthService } from '@views/misc/auth/auth.service';

import * as actions from './user.actions';
import { UserService } from './user.service';

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private userService: UserService,
  ) {}

  resendConfirmationEmail$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: () => this.userService.resendConfirmEmail(),
      successMessage: 'Validation email has been resent.',
      errorMessage: 'Sorry, there has been an error sending your validation email.',
      startAction: actions.userResendConfirmationEmailStart,
      successAction: actions.userResendConfirmationEmailSuccess,
      failAction: actions.userResendConfirmationEmailFail,
    }),
  );
}
