import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of, switchMap, withLatestFrom } from 'rxjs';

import { AuthService } from '@views/misc/auth/auth.service';

import * as actions from './notifications.actions';
import { NOTIFICATIONS_LOAD_COUNT } from './notifications.helper';
import { selectNotificationsLoadCount } from './notifications.selectors';
import { NotificationsService } from './notifications.service';

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private authService: AuthService,
    private store: Store,
  ) {}

  fetchNotificationsCurrentLimit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.notificationsFetchCurrentLimit),
      withLatestFrom(this.store.select(selectNotificationsLoadCount)),
      switchMap(([action, loadCount]) => {
        // Get the current limit and make that the page size for the fetch
        return of(actions.notificationsFetchListStart({ payload: { pageIndex: 1, pageSize: loadCount } }));
      }),
    ),
  );

  loadMoreNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.notificationsFetchMore),
      withLatestFrom(this.store.select(selectNotificationsLoadCount)),
      switchMap(([action, loadCount]) => [
        // Get the current limit and make that the page size for the fetch
        actions.notificationsUpdateLoadCount({
          loadCount: loadCount + NOTIFICATIONS_LOAD_COUNT,
        }),
        actions.notificationsFetchListStart({
          payload: {
            pageIndex: 1,
            pageSize: loadCount + NOTIFICATIONS_LOAD_COUNT,
          },
        }),
      ]),
    ),
  );

  fetchList$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: action => this.notificationsService.fetchList(action.payload),
      startAction: actions.notificationsFetchListStart,
      successAction: actions.notificationsFetchListSuccess,
      failAction: actions.notificationsFetchListFail,
    }),
  );

  fetchUnreadCount$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: () => this.notificationsService.fetchUnreadNotificationsCount(),
      startAction: actions.notificationsFetchUnreadCountStart,
      successAction: actions.notificationsFetchUnreadCountSuccess,
      failAction: actions.notificationsFetchUnreadCountFail,
    }),
  );

  updateItem$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: action => this.notificationsService.updateItem(action.payload.notificationId),
      startAction: actions.notificationsUpdateItemStart,
      successAction: actions.notificationsUpdateItemSuccess,
      failAction: actions.notificationsUpdateItemFail,
    }),
  );

  updateAll$ = createEffect(() =>
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: () => this.notificationsService.updateAll(),
      startAction: actions.notificationsMarkAllAsReadStart,
      successAction: actions.notificationsMarkAllAsReadSuccess,
      failAction: actions.notificationsMarkAllAsReadFail,
    }),
  );
}
