import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as statisticsRedux from './statistics.redux';

@NgModule({
  imports: [
    StoreModule.forFeature('investorStatistics', statisticsRedux.reducer),
    EffectsModule.forFeature([statisticsRedux.Effects]),
  ],
})
export class InvestorSatisticsModule {}
