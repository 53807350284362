import { createReducer, on } from '@ngrx/store';

import * as actions from './enums.actions';
import { EnumsState } from './enums.types';

const initialState = {
  items: null,
  isLoading: false,
  isError: false,
  isCached: false,
};

export const reducer = createReducer(
  initialState,

  on(
    actions.enumsFetchListStart,
    (state: EnumsState): EnumsState => ({
      ...state,
      isLoading: true,
    }),
  ),

  on(
    actions.enumsFetchListSuccess,
    (state: EnumsState, { payload }): EnumsState => ({
      ...state,
      items: payload,
      isLoading: false,
      isError: false,
      isCached: true,
    }),
  ),

  on(
    actions.enumsFetchListFail,
    (state: EnumsState): EnumsState => ({
      ...state,
      isLoading: false,
      isError: true,
    }),
  ),
);
