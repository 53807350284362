import { Injectable } from '@angular/core';

import { Actions, createEffect } from '@ngrx/effects';

import { AuthService } from '@views/misc/auth/auth.service';

import { FilesService } from '@core/utilities/file/files.service';

import * as actions from './files.actions';

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private filesService: FilesService,
    private authService: AuthService,
  ) {}

  fetchItem$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: action => this.filesService.fetchList(action.payload),
      errorMessage: 'Sorry, there has been an error fetching your files',
      startAction: actions.fileFetchListStart,
      successAction: actions.fileFetchListSuccess,
      failAction: actions.fileFetchListFail,
    }),
  );

  addItem$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: action => this.filesService.uploadFile(action.payload),
      errorMessage: 'Sorry, there has been an error uploading your file',
      successMessage: 'Your file has been uploaded',
      startAction: actions.fileAddItemStart,
      successAction: actions.fileAddItemSuccess,
      failAction: actions.fileAddItemFail,
      useMergeMap: true,
    }),
  );
}
