import { Authsignal } from '@authsignal/browser';

import { environment } from '@environments/environment';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable, catchError, from, map, switchMap, throwError } from 'rxjs';

import { authRefreshShortTermTokenStart } from '@views/misc/auth/auth.actions';
import { StorageService } from '@views/misc/auth/storage.service';

import { LoggerService } from '@core/utilities/logger/logger.service';

import * as actions from './interceptor.actions';
import { SpecialErrorCodes, interceptorModifiyResponse } from './interceptor.helper';
import { HttpErrorState } from './interceptor.types';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private contentNegotiation = true;
  private modifiedRequest: HttpRequest<any>;
  authsignal: Authsignal;

  constructor(
    private store: Store,
    private loggerService: LoggerService,
    private storageService: StorageService,
  ) {
    this.authsignal = new Authsignal({
      tenantId: environment.authSignalTenantId,
      baseUrl: environment.authSignalBaseUrl,
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('dc.services.visualstudio.com') || request.url.includes('api.raygun.io')) {
      // Skip interceptor if api call is for appInsights so that we don't get an infinite loop
      return next.handle(request);
    } else if (this.contentNegotiation) {
      // remove any undefined params
      let params = request.params;
      for (const key of request.params.keys()) {
        if (params.get(key) === 'undefined') {
          params = params.delete(key);
        }
      }

      this.modifiedRequest = request.clone({
        setHeaders: {
          // let's add the squirrel.envelope to the header
          Accept: 'application/squirrel.envelope',
          // Auth signal device id is added to all headers
          'X-Multifactor-Authentication-Device-Id': this.authsignal?.anonymousId,
        },
        params,
      });
    } else {
      this.modifiedRequest = request;
    }

    return next.handle(this.modifiedRequest).pipe(
      map((res: HttpEvent<any>) => {
        if (this.contentNegotiation && request.responseType !== 'blob') {
          // Check short term token expiry
          if (res instanceof HttpResponse && res.headers.has('x-authtoken-expires')) {
            const tokenExpires = res.headers.get('x-authtoken-expires');
            if (tokenExpires !== undefined && Number(tokenExpires) < 600) {
              // If token has less that 10 mins(600 seconds) then refresh it
              const longTermToken = this.storageService.getLongToken();
              if (longTermToken) {
                this.store.dispatch(authRefreshShortTermTokenStart({ longTermToken }));
              }
            }
          }
          // Skip this step if response type is blob as its wont have a payload
          if (res instanceof HttpResponse) {
            const modifiedResponse = res.clone({
              body: res.body ? res.body.payload : null,
            });
            return modifiedResponse;
          }
        } else {
          return res;
        }
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        let errorMessage = '';
        let modifiedError;
        let realError;
        if (errorResponse?.status?.toString() === SpecialErrorCodes.MfaRequired && errorResponse?.error?.payload?.url) {
          /**
           * ERROR 499: AuthSignal - if an api returns a 499 error it means it multifactor
           * authentication via an AuthSignal token is required. AuthSignal is launched via
           * the SDK and if succesful the token is added to the request and retried.
           */

          const modifiedAuthSignalError = {
            errors: [
              {
                status: SpecialErrorCodes.MfaRequired,
                errorMessage: 'Multi-factor authentication failed',
              },
            ],
            status: SpecialErrorCodes.MfaRequired,
          };
          this.store.dispatch(actions.interceptorMfaStarted());
          return from(
            this.authsignal.launch(errorResponse?.error?.payload?.url, {
              mode: 'popup',
              popupOptions: { width: '600px' },
            }),
          ).pipe(
            switchMap(authsignalResponse => {
              const modifiedRequestWithAuthSignal = this.modifiedRequest.clone({
                setHeaders: {
                  'X-Multifactor-Authentication-Token': authsignalResponse?.token,
                },
              });
              this.store.dispatch(actions.interceptorMfaEnded());
              return next.handle(modifiedRequestWithAuthSignal).pipe(
                map((res: HttpEvent<any>) => {
                  return interceptorModifiyResponse(res, this.contentNegotiation, request);
                }),
                catchError(() => {
                  return throwError(modifiedAuthSignalError);
                }),
              );
            }),
            catchError(() => {
              this.store.dispatch(actions.interceptorMfaEnded());
              return throwError(modifiedAuthSignalError);
            }),
          );
        }
        if (this.contentNegotiation) {
          // TODO: remove after back-end team fix the return msg for this scenario
          // exception for expired offer, as the BE is returning a diff payload
          if (errorResponse?.status === 404) {
            if (errorResponse?.error?.error === 'Investment sell offer has expired') {
              const newError = {
                errors: [
                  {
                    errorCode: 400,
                    errorMessage: errorResponse?.error?.error,
                    propertyName: 'isOfferExpired',
                  },
                ],
              };
              return throwError(newError);
            }
          }
          // -------------------------------------------------------------------

          modifiedError = new HttpErrorResponse({
            error: {
              errors: errorResponse?.error ? errorResponse?.error?.errors : errorResponse?.error,
            },
            headers: errorResponse?.headers,
            status: errorResponse?.status,
            statusText: errorResponse?.statusText,
            url: errorResponse?.url || undefined,
          });
        } else {
          modifiedError = new HttpErrorResponse({
            error: { errors: errorResponse?.error ? errorResponse?.error : errorResponse },
            headers: errorResponse?.headers,
            status: errorResponse?.status,
            statusText: errorResponse?.statusText,
            url: errorResponse?.url || undefined,
          });
        }

        if (modifiedError.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${modifiedError?.error?.message as string}`;
          this.loggerService.logEvent({ message: `Client-side? Error: ${errorMessage}` });
        } else {
          // server-side error
          if (modifiedError.status === 400) {
            if (request.url.search('v2/login') !== -1) {
              // authentication error

              // if more than one login error, we look for invalid credentials and
              // throw this one first
              if (modifiedError.error.errors.length) {
                const errors = modifiedError.error.errors;
                let newError: { errors: HttpErrorState[] } = { errors: [] };
                errors.forEach((err: HttpErrorState) => {
                  if (err.errorCode === '401' && err.errorMessage === 'InvalidCredentials') {
                    errorMessage = 'Email or password incorrect';
                    newError = {
                      errors: [
                        {
                          errorCode: err.errorCode,
                          errorMessage,
                          propertyName: 'email',
                        },
                      ],
                    };

                    // we want only this error to be displayed in the form
                    this.store.dispatch(actions.interceptorAddErrors({ payload: newError }));
                    return throwError(newError);
                  }
                });
                if (newError.errors.length) {
                  return throwError(newError);
                }
              }
              // no invalid credentials when multiple errors returned, let's get the first error only
              realError = modifiedError.error.errors[0];
            } else {
              // for any other page, we do care about all errors
              realError = modifiedError.error.errors;

              const errors: HttpErrorState[] = [];
              realError.forEach((err: HttpErrorState) => {
                // if different API version, will return entity.property
                // in this case we just need to remove the entity part of
                // the propertyName to match our form fields
                const propName =
                  err.propertyName.search(/\./g) >= 0 ? err.propertyName.split('.')[1] : err.propertyName;
                let errorCode = err.errorCode;
                if (err.errorMessage.includes('Your ID has expired')) {
                  // Add special errorcode 1001 for expired id,
                  // this is a short term fix as we don't have a good solution for
                  // finding the error we have to use a string match.
                  // Should be replace with better solution from backend;
                  errorCode = SpecialErrorCodes.IdExpired;
                } else if (err.errorCode === 'InvalidValue') {
                  // If the errorCode is InvalidValue this means the error is from
                  // an invalid form value so we will give it a special errorCode
                  errorCode = SpecialErrorCodes.InvalidValue;
                }
                errors.push({
                  errorCode,
                  errorMessage: err.errorMessage,
                  propertyName: propName,
                });
              });

              const newError = { errors };

              // we want this error to be displayed in the form
              this.store.dispatch(actions.interceptorAddErrors({ payload: newError }));

              return throwError(newError);
            }
          }

          if (modifiedError.status === 401) {
            // probably tokens expired, need to force logout
            const newError = {
              errors: [
                {
                  errorCode: '401',
                  errorMessage: 'Authentication expired',
                  propertyName: 'login',
                },
              ],
            };

            return throwError(newError);
          }

          if (modifiedError.status === 404) {
            return throwError(modifiedError);
          }
        }
        return throwError(modifiedError);
      }),
    );
  }
}
