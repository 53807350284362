import { createAction, props } from '@ngrx/store';

import {
  FetchFilesRequest,
  FetchFilesResponse,
  UploadFileRequest,
  UploadFileResponse,
} from '@core/utilities/file/files.types';

export const fileFetchListStart = createAction('FILES/FETCH_LIST_START', props<{ payload: FetchFilesRequest }>());
export const fileFetchListSuccess = createAction('FILES/FETCH_LIST_SUCCESS', props<{ payload: FetchFilesResponse }>());
export const fileFetchListFail = createAction('FILES/FETCH_LIST_FAIL');

export const fileResetList = createAction('FILES/RESET_LIST');

export const fileAddItemStart = createAction('FILES/ADD_ITEM_START', props<{ payload: UploadFileRequest }>());
export const fileAddItemSuccess = createAction('FILES/ADD_ITEM_SUCCESS', props<{ payload: UploadFileResponse }>());
export const fileAddItemFail = createAction('FILES/ADD_ITEM_FAIL');
