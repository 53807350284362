import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';

import { exhaustMap, from, map } from 'rxjs';

import { convertEnumValueToTextFromKey } from './enums.helpers';
import * as selectors from './enums.selectors';
import { EnumsState } from './enums.types';

@Injectable({
  providedIn: 'root',
})
export class EnumsService {
  constructor(
    private http: HttpClient,
    private store: Store<{ enums: EnumsState }>,
  ) {}

  /**
   * Fetch the ENUM's list from either the server (a fresh version) or from our
   * session state (stale version from the Redux store).
   *
   * @note We do not persist the ENUM's more heavily via application cache as
   * "apparently" this reference will become very dynamic in the future - in that
   * it will be connected to a CMS and the wider team will be switching out
   * reference frequently. This poses another scary proposition of expected data
   * disappearing... but we will cross that bridge when / if we ever get there.
   */
  fetchList() {
    return this.store.pipe(
      select(selectors.selectEnums),
      exhaustMap(enums => {
        return enums.isCached
          ? /**
             * YAY! We have a cached version... lets just return that as a "simulated"
             * fetch request.
             */
            from(Promise.resolve(enums.items))
          : /**
             * BOO! We need to get a new version of the ENUM's data by make a
             * legitimate HTTP request.
             */
            this.http.get(`${environment.endpointApiTwo}/v2/enums/borrower-process-enums`, {
              headers: { 'Content-Type': 'application/json' },
            });
      }),
    );
  }

  /**
   * Leverages the ENUM helper `convertEnumValueToTextFromKey` BUT does not
   * require the developer to supply the ENUM object from the store and will
   * instead retrieve it for you.
   *
   * @note the generic helper `convertEnumValueToTextFromKey` should be used
   * where possible as for situations like selectors you can memoize the result
   * and increase render performance.
   */
  getThenConvertEnumValueToTextFromKey = (key: string, value: number): void => {
    this.store.pipe(
      select(state => state.enums),
      map((enums: EnumsState) => convertEnumValueToTextFromKey(enums.items, key, value)),
    );
  };
}
