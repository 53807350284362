import { ErrorHandler, Injectable } from '@angular/core';

import { LoggerService } from './logger.service';

@Injectable()
export class LoggerErrorHandlerService extends ErrorHandler {
  constructor(private loggerService: LoggerService) {
    super();
  }

  handleError(error: Error): void {
    this.loggerService.logException(error); // Manually log exception
  }
}
