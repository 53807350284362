import { Injectable } from '@angular/core';

import { Actions, createEffect } from '@ngrx/effects';

import { AuthService } from '@views/misc/auth/auth.service';

import * as actions from './entities.actions';
import { SquirrelEntitiesService } from './entities.service';

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private squirrelEntitiesService: SquirrelEntitiesService,
    private authService: AuthService,
  ) {}

  fetchList$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: () => this.squirrelEntitiesService.getInvestorEntities(),
      errorMessage: 'Sorry, there has been an error fetching your investment accounts',
      startAction: actions.entitiesFetchListStart,
      successAction: actions.entitiesFetchListSuccess,
      failAction: actions.entitiesFetchListFail,
      useMergeMap: true,
    }),
  );

  fetchItem$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: action => this.squirrelEntitiesService.getInvestorEntity(action.payload),
      errorMessage: 'Sorry, there has been an error fetching your investment account data',
      startAction: actions.entitiesFetchItemStart,
      successAction: actions.entitiesFetchItemSuccess,
      failAction: actions.entitiesFetchItemFail,
    }),
  );

  updateItem$ = createEffect(
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: action => this.squirrelEntitiesService.putInvestorEntities(action.payload),
      errorMessage: 'Sorry, there has been an error updating your investment account data',
      startAction: actions.entitiesUpdateItemStart,
      successAction: actions.entitiesUpdateItemSuccess,
      failAction: actions.entitiesUpdateItemFail,
    }),
  );
}
