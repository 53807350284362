import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Observable, catchError, map } from 'rxjs';

import { LinkService } from './link.service';
import { PostLinkToken } from './link.types';

@Injectable({
  providedIn: 'root',
})
/**
 * Resolver to redirect to provided url
 */
export class LinkResolver {
  constructor(private linkService: LinkService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let tokens: PostLinkToken[] = [];
    // Convert params to request body
    for (const key in route.queryParams) {
      if (route && route.queryParams && route.queryParams[key]) {
        tokens = [...tokens, { key, value: route.queryParams[key] }];
      }
    }
    if (tokens.length > 0) {
      return this.linkService.postLink({ tokens }).pipe(
        map(response => {
          if (response.url !== null) {
            // redirect to provided link
            location.replace(response.url);
            return false;
          }
          // If the url is null then render invalid link page
          return true;
        }),
        catchError(() => {
          // If the api fails then render invalid link page
          return [true];
        }),
      );
    } else {
      // If there are no token then render invalid link page
      return true;
    }
  }
}
