import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from '@views/misc/auth/auth.service';
import { StorageService } from '@views/misc/auth/storage.service';

import {
  NotificationsGetQuery,
  NotificationsGetResponse,
  NotificationsReadPutResponse,
  NotificationsUnreadCountGetResponse,
} from './notifications.types';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private storageService: StorageService,
  ) {}

  fetchList(payload: NotificationsGetQuery): Observable<NotificationsGetResponse> {
    return this.http.get<NotificationsGetResponse>(`${environment.endpointApiOne}/v1/notifications`, {
      headers: this.authService.createAuthHeaders(),
      params: {
        pageIndex: `${payload.pageIndex}`,
        pageSize: `${payload.pageSize}`,
      },
    });
  }

  fetchUnreadNotificationsCount(): Observable<NotificationsUnreadCountGetResponse> {
    return this.http.get<NotificationsUnreadCountGetResponse>(
      `${environment.endpointApiOne}/v1/notifications/unread-count`,
      {
        headers: this.authService.createAuthHeaders(),
      },
    );
  }

  /**
   * update one notification item as read
   */
  updateItem(notificationId: number): Observable<NotificationsReadPutResponse> {
    return this.http.put<NotificationsReadPutResponse>(
      `${environment.endpointApiOne}/v1/notifications/${notificationId}/read`,
      null,
      {
        headers: this.authService.createAuthHeaders(),
      },
    );
  }

  /**
   * update all notifications to be read
   */
  updateAll(): Observable<unknown> {
    const userId = this.storageService.getUserId();
    return this.http.put(`${environment.endpointApiOne}/v1/notifications/${userId}/mark-all-as-read`, null, {
      headers: this.authService.createAuthHeaders(),
    });
  }
}
