import { createReducer, on } from '@ngrx/store';

import * as actions from './notification-banners.actions';
import { NotificationBannersFetchResponse } from './notification-banners.types';

export interface IInvestorNotificationsState {
  notifications: NotificationBannersFetchResponse[];
  notificationsLoading: boolean;
  notificationsLoaded: boolean;
}

export const initialState: IInvestorNotificationsState = {
  notifications: undefined,
  notificationsLoading: false,
  notificationsLoaded: false,
};

export const reducer = createReducer(
  initialState,

  on(actions.notificationBannersFetchStart, state => ({
    ...state,
    notificationsLoading: true,
    notificationsLoaded: false,
  })),

  on(actions.notificationBannersFetchSuccess, (state, { payload }) => ({
    ...state,
    notifications: payload ? [...payload] : [],
    notificationsLoading: false,
    notificationsLoaded: true,
  })),

  on(actions.notificationBannersFetchFail, state => ({
    ...state,
    notificationsLoading: false,
    notificationsLoaded: false,
  })),
);
