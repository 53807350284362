import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { FileItemResponse } from '@core/utilities/file/files.types';

import { FilesState } from './files.types';

export const adapter: EntityAdapter<FileItemResponse> = createEntityAdapter<FileItemResponse>({
  selectId: item => item.fileId,
  sortComparer: false,
});

export const initialState: FilesState = adapter.getInitialState({
  metadata: null,
  isLoading: false,
  isLoaded: false,
  isError: false,
});
