import { createReducer, on } from '@ngrx/store';

import * as actions from './statistics.actions';
import { InvestorStatisticsState } from './statistics.types';

const initialState: InvestorStatisticsState = {
  brochureStatisticsIsLoaded: false,
  brochureStatisticsIsLoading: false,
  brochureStatisticsIsError: false,
  brochureStatistics: undefined,
  statisticsIsLoaded: false,
  statisticsIsLoading: false,
  statisticsIsError: false,
  statistics: undefined,
  marketplaceCustomIsLoaded: false,
  marketplaceCustomIsLoading: false,
  marketplaceCustomIsError: false,
  marketplaceCustom: undefined,
};

export const reducer = createReducer(
  initialState,

  on(actions.statisticsBrochureFetchListStart, state => ({
    ...state,
    brochureStatisticsIsLoading: true,
  })),

  on(actions.statisticsBrochureFetchListSuccess, (state, { payload }) => {
    return {
      ...state,
      brochureStatistics: { ...payload },
      brochureStatisticsIsLoaded: true,
      brochureStatisticsIsLoading: false,
      brochureStatisticsIsError: false,
    };
  }),
  on(actions.statisticsBrochureFetchListFail, state => ({
    ...state,
    brochureStatisticsIsLoaded: false,
    brochureStatisticsIsLoading: false,
    brochureStatisticsIsError: true,
  })),

  on(actions.statisticsFetchListStart, state => ({
    ...state,
    statisticsIsLoading: true,
  })),

  on(actions.statisticsFetchListSuccess, (state, { payload }) => {
    return {
      ...state,
      statistics: { ...payload },
      statisticsIsLoaded: true,
      statisticsIsLoading: false,
      statisticsIsError: false,
    };
  }),
  on(actions.statisticsFetchListFail, state => ({
    ...state,
    statisticsIsLoaded: false,
    statisticsIsLoading: false,
    statisticsIsError: true,
  })),

  on(actions.statisticsMarketplaceCustomFetchStart, state => ({
    ...state,
    marketplaceCustomIsLoading: true,
  })),

  on(actions.statisticsMarketplaceCustomFetchSuccess, (state, { payload }) => {
    return {
      ...state,
      marketplaceCustom: { ...payload },
      marketplaceCustomIsLoaded: true,
      marketplaceCustomIsLoading: false,
      marketplaceCustomIsError: false,
    };
  }),
  on(actions.statisticsMarketplaceCustomFetchFail, state => ({
    ...state,
    marketplaceCustomIsLoaded: false,
    marketplaceCustomIsLoading: false,
    marketplaceCustomIsError: true,
  })),
);
