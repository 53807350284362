import { environment } from '@environments/environment';

import { ErrorHandler, NgModule } from '@angular/core';

import { LoggerErrorHandlerService } from './logger-error-handler.service';
import { LoggerService } from './logger.service';

@NgModule({
  providers: environment.production
    ? // only use a cutom ErrorHandler for prod builds otherwise
      // error messages will be hidden in development mode
      [{ provide: ErrorHandler, useClass: LoggerErrorHandlerService }, LoggerService]
    : [LoggerService],
})
export class LoggerModule {}
