import { createReducer, on } from '@ngrx/store';

import * as actions from './interceptor.actions';
import { IFormErrorsState } from './interceptor.types';

export const initialFormErrorsState: IFormErrorsState = {
  mfaInProgress: false,
  errors: [
    {
      errorCode: '',
      errorMessage: '',
      propertyName: '',
    },
  ],
};

export const reducer = createReducer(
  initialFormErrorsState,

  on(actions.interceptorMfaStarted, state => ({
    ...state,
    mfaInProgress: true,
  })),

  on(actions.interceptorMfaEnded, state => ({
    ...state,
    mfaInProgress: false,
  })),

  on(actions.interceptorAddErrors, (state, { payload }) => ({
    ...state,
    errors: payload?.errors,
  })),

  on(actions.interceptorClearErrors, state => ({
    ...state,
    errors: initialFormErrorsState.errors,
  })),
);
