import { createReducer, on } from '@ngrx/store';

import * as actions from './legal-copy.actions';
import { LegalCopyFetchResponse } from './legal-copy.types';

export interface ILegalCopyState {
  secondaryMarket: LegalCopyFetchResponse;
  secondaryMarketLoading: boolean;
  secondaryMarketLoaded: boolean;
  disclosureStatement: LegalCopyFetchResponse;
  disclosureStatementLoading: boolean;
  disclosureStatementLoaded: boolean;
  investorAgreement: LegalCopyFetchResponse;
  investorAgreementLoading: boolean;
  investorAgreementLoaded: boolean;
}

export const initialState: ILegalCopyState = {
  secondaryMarket: undefined,
  secondaryMarketLoading: false,
  secondaryMarketLoaded: false,
  disclosureStatement: undefined,
  disclosureStatementLoading: false,
  disclosureStatementLoaded: false,
  investorAgreement: undefined,
  investorAgreementLoading: false,
  investorAgreementLoaded: false,
};

export const reducer = createReducer(
  initialState,

  on(actions.legalCopyFetchSecondaryMarketStart, state => ({
    ...state,
    secondaryMarketLoading: true,
    secondaryMarketLoaded: false,
  })),

  on(actions.legalCopyFetchSecondaryMarketSuccess, (state, { payload }) => ({
    ...state,
    secondaryMarket: { ...payload },
    secondaryMarketLoading: false,
    secondaryMarketLoaded: true,
  })),

  on(actions.legalCopyFetchSecondaryMarketFail, state => ({
    ...state,
    secondaryMarketLoading: false,
    secondaryMarketLoaded: false,
  })),

  on(actions.legalCopyFetchDisclosureStatementStart, state => ({
    ...state,
    disclosureStatementLoading: true,
    disclosureStatementLoaded: false,
  })),

  on(actions.legalCopyFetchDisclosureStatementSuccess, (state, { payload }) => ({
    ...state,
    disclosureStatement: { ...payload },
    disclosureStatementLoading: false,
    disclosureStatementLoaded: true,
  })),

  on(actions.legalCopyFetchDisclosureStatementFail, state => ({
    ...state,
    disclosureStatementLoading: false,
    disclosureStatementLoaded: false,
  })),

  on(actions.legalCopyFetchInvestorAgreementStart, state => ({
    ...state,
    investorAgreementLoading: true,
    investorAgreementLoaded: false,
  })),

  on(actions.legalCopyFetchInvestorAgreementSuccess, (state, { payload }) => ({
    ...state,
    investorAgreement: { ...payload },
    investorAgreementLoading: false,
    investorAgreementLoaded: true,
  })),

  on(actions.legalCopyFetchInvestorAgreementFail, state => ({
    ...state,
    investorAgreementLoading: false,
    investorAgreementLoaded: false,
  })),
);
