import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, of, retry, switchMap } from 'rxjs';

import { SnackBarService } from '@components/snackbar/snackbar.service';

import * as actions from './enums.actions';
import { EnumsService } from './enums.service';

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private enumsService: EnumsService,
    private snackBarService: SnackBarService,
  ) {}

  fetchList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.enumsFetchListStart),
      switchMap(() => {
        return this.enumsService.fetchList().pipe(
          retry(1),
          map(payload => {
            return actions.enumsFetchListSuccess({ payload });
          }),
          catchError(() => {
            this.snackBarService.setError('Sorry, there has been an issue fetching your page');
            return of(actions.enumsFetchListFail());
          }),
        );
      }),
    ),
  );
}
