import { createAction, props } from '@ngrx/store';

import { NotificationBannersFetchResponse } from './notification-banners.types';

export const notificationBannersFetchStart = createAction('NOTIFICATION_BANNERS/FETCH_START');

export const notificationBannersFetchSuccess = createAction(
  'NOTIFICATION_BANNERS/FETCH_SUCCESS',
  props<{ payload: NotificationBannersFetchResponse[] }>(),
);
export const notificationBannersFetchFail = createAction('NOTIFICATION_BANNERS/FETCH_FAIL');
