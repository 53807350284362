import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from '@views/misc/auth/auth.service';

import {
  InvestorEntitiesGetResponse,
  InvestorEntitiesPutRequestBody,
  InvestorEntitiesPutResponse,
  InvestorEntityGetResponse,
} from './entities.types';

@Injectable({
  providedIn: 'root',
})
export class SquirrelEntitiesService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  getInvestorEntities(): Observable<InvestorEntitiesGetResponse> {
    return this.http.get<InvestorEntitiesGetResponse>(`${environment.endpointApiOne}/v2/investor-entities`, {
      headers: this.authService.createAuthHeaders(),
      params: { pageIndex: 0, pageSize: 100 },
    });
  }

  getInvestorEntity(payload: {
    // Not actually part of the BED payload but is sent through from the UI to
    // build the dynamic URL endpoint.
    investorEntityId: number;
  }): Observable<InvestorEntityGetResponse> {
    const { investorEntityId } = payload;

    return this.http.get<InvestorEntityGetResponse>(
      `${environment.endpointApiOne}/v2/investor-entities/${investorEntityId}`,
      {
        headers: this.authService.createAuthHeaders(),
      },
    );
  }

  putInvestorEntities(requestBody: InvestorEntitiesPutRequestBody): Observable<InvestorEntitiesPutResponse> {
    const { entityId } = requestBody;

    return this.http.put<InvestorEntitiesPutResponse>(
      `${environment.endpointApiOne}/v2/investor-entities/${entityId}`,
      requestBody,
      {
        headers: this.authService.createAuthHeaders(),
      },
    );
  }
}
