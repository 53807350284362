import { createReducer, on } from '@ngrx/store';

import * as actions from './notifications.actions';
import { NOTIFICATIONS_LOAD_COUNT } from './notifications.helper';
import { NotificationsState } from './notifications.types';

export const initialState: NotificationsState = {
  notificationsResponse: { items: undefined, metadata: undefined },
  loadCount: NOTIFICATIONS_LOAD_COUNT,
  unreadCount: undefined,
  isLoaded: false,
  isLoading: false,
  isError: false,
};

export const reducer = createReducer(
  initialState,

  on(actions.notificationsFetchListStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.notificationsFetchListSuccess, (state, { payload }) => ({
    ...state,
    notificationsResponse: { ...payload },
    isLoaded: true,
    isLoading: false,
    isError: false,
  })),

  on(actions.notificationsFetchListFail, state => ({
    ...state,
    isLoading: false,
    isError: true,
  })),

  on(actions.notificationsFetchUnreadCountSuccess, (state, { payload }) => ({
    ...state,
    unreadCount: payload?.count,
  })),

  on(actions.notificationsResetList, state => ({
    ...state,
    notificationsResponse: { ...initialState.notificationsResponse },
  })),

  on(actions.notificationsUpdateItemStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.notificationsUpdateItemSuccess, (state, { payload }) => ({
    ...state,
    notificationsResponse: {
      ...state.notificationsResponse,
      items: state.notificationsResponse?.items?.map(item => {
        if (item.notificationId === payload.notificationId) {
          return payload;
        }
        return item;
      }),
    },
    isLoading: false,
    isError: false,
  })),

  on(actions.notificationsUpdateLoadCount, (state, { loadCount }) => ({
    ...state,
    loadCount,
  })),

  on(actions.notificationsUpdateItemFail, state => ({
    ...state,
    isLoading: false,
    isError: true,
  })),

  on(actions.notificationsMarkAllAsReadStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.notificationsMarkAllAsReadSuccess, state => ({
    ...state,
    /**
     * manually update all items to be read and pass it in the payload
     * to give back to the store as we don't receive a response from
     * the server.
     */
    notificationsResponse: {
      ...state.notificationsResponse,
      items: state?.notificationsResponse?.items?.map(item => ({
        ...item,
        isRead: true,
      })),
    },
    isLoading: false,
    isError: false,
  })),

  on(actions.notificationsMarkAllAsReadFail, state => ({
    ...state,
    isLoading: false,
    isError: true,
  })),

  on(actions.notificationsResetUnreadCount, state => ({
    ...state,
    unreadCount: initialState.unreadCount,
  })),
);
