import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, switchMap } from 'rxjs';

import { SnackBarService } from '@components/snackbar/snackbar.service';

import * as actions from './legal-copy.actions';
import { LegalCopyService } from './legal-copy.service';

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private legalCopyService: LegalCopyService,
    private snackBarService: SnackBarService,
  ) {}

  fetchLegalCopySecondaryMarket$ = createEffect(() =>
    // Doesn't use network layer as does not need authorisation
    this.actions$.pipe(
      ofType(actions.legalCopyFetchSecondaryMarketStart),
      switchMap(() => {
        return this.legalCopyService
          .fetchLegalCopy({
            contentTypeId: 'investorLegalDocuments',
            id: 'SecondaryMarket_v2',
          })
          .pipe(
            map(payload => {
              return actions.legalCopyFetchSecondaryMarketSuccess({ payload });
            }),
            catchError(err => {
              const errorMessage =
                err?.error?.errorMessage?.length > 0
                  ? err.error.errorMessage
                  : 'Sorry, there has been an error fetching the Secondary Market Terms and Conditions';

              this.snackBarService.setError(errorMessage);
              return [actions.legalCopyFetchSecondaryMarketFail()];
            }),
          );
      }),
    ),
  );

  fetchLegalCopyDisclosureSatement$ = createEffect(() =>
    // Doesn't use network layer as does not need authorisation
    this.actions$.pipe(
      ofType(actions.legalCopyFetchDisclosureStatementStart),
      switchMap(() => {
        return this.legalCopyService
          .fetchLegalCopy({
            contentTypeId: 'investorLegalDocuments',
            id: 'Disclosure_Statement_v2_2',
          })
          .pipe(
            map(payload => {
              return actions.legalCopyFetchDisclosureStatementSuccess({ payload });
            }),
            catchError(err => {
              const errorMessage =
                err?.error?.errorMessage?.length > 0
                  ? err.error.errorMessage
                  : 'Sorry, there has been an error fetching the Disclosure Statement Terms and Conditions';

              this.snackBarService.setError(errorMessage);
              return [actions.legalCopyFetchDisclosureStatementFail()];
            }),
          );
      }),
    ),
  );

  fetchLegalCopyInvestorAgreement$ = createEffect(() =>
    // Doesn't use network layer as does not need authorisation
    this.actions$.pipe(
      ofType(actions.legalCopyFetchInvestorAgreementStart),
      switchMap(() => {
        return this.legalCopyService
          .fetchLegalCopy({
            contentTypeId: 'investorTerms',
            id: '200718_0150_6',
          })
          .pipe(
            map(payload => {
              return actions.legalCopyFetchInvestorAgreementSuccess({ payload });
            }),
            catchError(err => {
              const errorMessage =
                err?.error?.errorMessage?.length > 0
                  ? err.error.errorMessage
                  : 'Sorry, there has been an error fetching the Investor Agreement Terms and Conditions';

              this.snackBarService.setError(errorMessage);
              return [actions.legalCopyFetchInvestorAgreementFail()];
            }),
          );
      }),
    ),
  );
}
