import rg4js from 'raygun4js';

import { environment } from '@environments/environment';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, catchError, tap, throwError } from 'rxjs';

import { CustomerFormsStore } from './customer-forms.store';

@Injectable()
export class CustomerFormInterceptor implements HttpInterceptor {
  readonly store = inject(CustomerFormsStore);
  router = inject(Router);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Don't intercept non customer form paths
    if (!request.url.startsWith(environment.endpointApiPortalTwo)) return next.handle(request);

    // Don't intercept participant routes
    if (request.url.includes('/participants')) return next.handle(request);

    // Apply auth header
    request = this.applyAuthHeader(request);

    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) this.refreshFormToken(event.headers.get('X-Refreshedtoken'));
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        // The server error message is being overridden below so log the original error with raygun.
        if (environment.rayGunApiKey) this.logError(errorResponse);

        let errorMessage = errorResponse.message;
        if (errorResponse.status === 401) {
          errorMessage = 'This request was unauthorised. Please retry your link from your emails.';
          this.router.navigate(['customer-forms', 'ended']);
        } else if (errorResponse.status >= 500) {
          errorMessage = 'Sorry we encountered an error. Please contact support if this continues.';
        }

        return throwError(() => new Error(errorMessage));
      }),
    );
  }

  applyAuthHeader(request: HttpRequest<any>) {
    return request.clone({
      headers: request.headers.set('Authorization', this.store.formAuthHeader()),
    });
  }

  refreshFormToken(refreshedToken: string | null) {
    if (refreshedToken) {
      this.store.updateFormTokenParamsFromString(refreshedToken);
    }
  }

  logError(error: Error) {
    rg4js('send', {
      error,
      customData: { loanApplication: this.store.loanApplicationId },
    });
  }
}
