import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from '@views/misc/auth/auth.service';

import { StorageService } from '../auth/storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private storageService: StorageService,
  ) {}

  resendConfirmEmail(): Observable<Record<string, unknown>> {
    const userId = this.storageService.getUserId();
    return this.http.post<Record<string, unknown>>(
      `${environment.endpointApiOne}/v1/users/${userId}/resend-confirm-email`,
      {},
      {
        headers: this.authService.createAuthHeaders(),
      },
    );
  }
}
