import { createReducer, on } from '@ngrx/store';

import * as actions from './entities.actions';
import { EntitiesState } from './entities.types';

export const initialState: EntitiesState = {
  entities: undefined,
  entity: undefined,
  entitiesIsLoaded: false,
  entitiesIsLoading: false,
  entitiesIsError: false,
  entityIsLoaded: false,
  entityIsLoading: false,
  entityIsError: false,
};

export const reducer = createReducer(
  initialState,

  on(actions.entitiesFetchListStart, state => ({
    ...state,
    entitiesIsLoading: true,
    entitiesIsError: false,
  })),

  on(actions.entitiesFetchListSuccess, (state, { payload }) => ({
    ...state,
    entities: { ...payload },
    entitiesIsLoaded: true,
    entitiesIsLoading: false,
    entitiesIsError: false,
  })),

  on(actions.entitiesFetchListFail, state => ({
    ...state,
    entitiesIsLoaded: false,
    entitiesIsLoading: false,
    entitiesIsError: true,
  })),

  on(actions.entitiesReset, () => ({
    ...initialState,
  })),

  on(actions.entitiesFetchItemStart, state => ({
    ...state,
    entityIsLoading: true,
    entityIsError: true,
  })),

  on(actions.entitiesFetchItemSuccess, (state, action) => ({
    ...state,
    entity: action.payload || null,
    entityIsLoaded: true,
    entityIsLoading: false,
    entityIsError: true,
  })),

  on(actions.entitiesFetchItemFail, state => ({
    ...state,
    entityIsLoaded: false,
    entityIsLoading: false,
    entityIsError: true,
  })),

  on(actions.entitiesUpdateItemStart, state => ({
    ...state,
    entityIsLoading: true,
  })),

  on(actions.entitiesUpdateItemSuccess, (state, { payload }) => ({
    ...state,
    entity: payload || null,
    entityIsLoading: false,
    entityIsError: false,
  })),

  on(actions.entitiesUpdateItemFail, state => ({
    ...state,
    entityIsLoading: false,
    entityIsError: true,
  })),

  on(actions.entitiesResetItem, state => ({
    ...state,
    entity: null,
    entityIsLoaded: false,
  })),
);
