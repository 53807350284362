import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { EMPTY, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * Resolver to stop routing to a page when one of
 * the params is not a number e.g null
 */
export class NumberParamGuardResolver {
  constructor(private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    for (const key in route.params) {
      if (isNaN(Number(route.params[key]))) {
        /**
         * If any param is not a number then redirect to page not found view,
         * '/page-not-found' is not a real route its just away to trigger the not found view
         */
        void this.router.navigate(['/page-not-found'], { skipLocationChange: true });
        return EMPTY;
      }
    }

    return true;
  }
}
