import { Injectable } from '@angular/core';

import { Actions, createEffect } from '@ngrx/effects';

import { AuthService } from '@views/misc/auth/auth.service';

import * as actions from './security.actions';
import { SecurityService } from './security.service';

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private securityService: SecurityService,
    private authService: AuthService,
  ) {}

  fetchUserMfa$ = createEffect(() =>
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: () => this.securityService.getUserMfa(),
      errorMessage: 'Sorry, there has been an error fetching your MFA details',
      startAction: actions.securityFetchUserMfaStart,
      successAction: actions.securityFetchUserMfaSuccess,
      failAction: actions.securityFetchUserMfaFail,
    }),
  );

  updateUserMfa$ = createEffect(() =>
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: action => this.securityService.postUserMfa(action.payload),
      errorMessage: 'Sorry, there has been an error updating your MFA details',
      startAction: actions.securityUpdateUserMfaStart,
      successAction: actions.securityUpdateUserMfaSuccess,
      failAction: actions.securityUpdateUserMfaFail,
    }),
  );

  MfaManageUrl$ = createEffect(() =>
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: () => this.securityService.postMfaManageUrl(),
      errorMessage: 'Sorry, there has been an error open your authenticator details',
      startAction: actions.securityMfaManageStart,
      successAction: actions.securityMfaManageSuccess,
      failAction: actions.securityMfaManageFail,
    }),
  );
}
