import { createReducer, on } from '@ngrx/store';

import * as actions from './bank-accounts.actions';
import { BankAccountsState } from './bank-accounts.types';

export const initialState: BankAccountsState = {
  bankAccountsResponse: undefined,
  isLoaded: false,
  isLoading: false,
  isError: false,
};

export const reducer = createReducer(
  initialState,

  on(actions.bankAccountsFetchListStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.bankAccountsFetchListSuccess, (state, { payload }) => ({
    ...state,
    bankAccountsResponse: { ...payload },
    isLoaded: true,
    isLoading: false,
    isError: false,
  })),

  on(actions.bankAccountsFetchListFail, state => ({
    ...state,
    isLoaded: false,
    isLoading: false,
    isError: true,
  })),

  on(actions.bankAccountsResetList, () => ({
    ...initialState,
  })),
);
