import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { NotificationBannersFetchResponse } from './notification-banners.types';

@Injectable({
  providedIn: 'root',
})
export class NotificationBannersService {
  constructor(private http: HttpClient) {}
  fetchNotifications(): Observable<NotificationBannersFetchResponse[]> {
    return this.http.get<NotificationBannersFetchResponse[]>(
      `${environment.endpointApiOne}/v1/content/notificationBanner`,
    );
  }
}
