import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from '@views/misc/auth/auth.service';
import { StorageService } from '@views/misc/auth/storage.service';

import {
  BorrowerDashboardContinueUrlRequest,
  BorrowerDashboardContinueUrlResponse,
  BorrowerDashboardResponse,
} from './borrower.types';

@Injectable({
  providedIn: 'root',
})
export class BorrowerDashboardService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private storageService: StorageService,
  ) {}

  getBorrowerDashboard(): Observable<BorrowerDashboardResponse> {
    const userId = this.storageService.getUserId();
    return this.http.get<BorrowerDashboardResponse>(`${environment.endpointApiTwo}/v2/dashboard/borrower/${userId}`, {
      headers: this.authService.createAuthHeaders(),
    });
  }

  getContinueUrl(payload: BorrowerDashboardContinueUrlRequest): Observable<BorrowerDashboardContinueUrlResponse> {
    const userId = this.storageService.getUserId();
    return this.http.put<BorrowerDashboardContinueUrlResponse>(
      `${environment.endpointApiTwo}/v2/in-progress-loan-applications/${payload.inProgressLoanApplicationId}/generate/continue-url/${userId}`,
      null,
      { headers: this.authService.createAuthHeaders() },
    );
  }

  // TODO either use with new OLA or remove
  // getBorrowerApplication(payload: { applicationId: string | number }): Observable<BorrowerApplicationResponse> {
  //   return this.http.get<BorrowerApplicationResponse>(
  //     `${environment.endpointApiPortal}/v1/applications/${payload.applicationId}`,
  //     {
  //       headers: this.authService.createAuthHeaders(),
  //     },
  //   );
  // }

  // getBorrowerApplications(): Observable<BorrowerApplicationsResponse> {
  //   return this.http.get<BorrowerApplicationsResponse>(`${environment.endpointApiPortal}/v1/applications`, {
  //     headers: this.authService.createAuthHeaders(),
  //   });
  // }
}
