import { createReducer, on } from '@ngrx/store';

import * as actions from './security.actions';
import { SecurityState } from './security.types';

const initialState: SecurityState = {
  userMfaResponse: undefined,
  userMfaIsLoading: false,
  userMfaIsLoaded: false,
  userMfaIsError: false,
};

export const reducer = createReducer(
  initialState,

  on(actions.securityFetchUserMfaStart, state => ({
    ...state,
    userMfaIsLoading: true,
  })),

  on(actions.securityFetchUserMfaSuccess, (state, { payload }) => ({
    ...state,
    userMfaResponse: { ...payload },
    userMfaIsLoading: false,
    userMfaIsLoaded: true,
    userMfaIsError: false,
  })),

  on(actions.securityFetchUserMfaFail, state => ({
    ...state,
    userMfaIsLoading: false,
    userMfaIsLoaded: false,
    userMfaIsError: true,
  })),

  on(actions.securityUpdateUserMfaSuccess, (state, { payload }) => ({
    ...state,
    userMfaResponse: { ...payload },
  })),

  on(actions.securityMfaManageSuccess, (state, { payload }) => ({
    ...state,
    userMfaResponse: { ...payload },
  })),

  on(actions.securityReset, () => ({
    ...initialState,
  })),
);
