export interface NotificationsBannerContent {
  title: string;
  content: string;
  showToInvestors?: showToEnum;
  showToBorrowers?: showToEnum;
}

export enum notificationBannerTypeEnum {
  Investor = 'Investor',
  Borrower = 'Borrower',
  Both = 'Both',
}

export enum showToEnum {
  True = 'True',
  False = 'False',
}

export interface NotificationBannersFetchResponse {
  contentType: string;
  id: string;
  content: NotificationsBannerContent;
}
