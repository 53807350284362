import { createReducer, on } from '@ngrx/store';

import * as actions from './auth.actions';
import { AuthState } from './auth.types';

export const initialState: AuthState = {
  isLoading: false,
  isError: false,
  user: undefined,
  userLoaded: false,
  userLoading: false,
  userVerify: undefined,
  userVerifyLoading: false,
  userVerifyLoaded: false,
};

export const reducer = createReducer(
  initialState,

  on(actions.authCreateUserSessionStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.authCreateUserSessionFromLongTermTokenStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.authCreateUserSessionSuccess, state => ({
    ...state,
    isLoading: false,
    isError: false,
  })),

  on(actions.authCreateUserSessionFail, state => ({
    ...state,
    isLoading: false,
    isError: true,
  })),

  on(actions.authDeleteUserSessionStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.authDeleteUserSessionSuccess, state => ({
    ...state,
    isLoading: false,
    isError: false,
    user: initialState.user,
    userVerify: initialState.userVerify,
    userLoading: false,
    userLoaded: false,
  })),

  on(actions.authDeleteUserSessionFail, state => ({
    ...state,
    isLoading: false,
    isError: true,
  })),

  on(actions.authDeleteUserAccountStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.authDeleteUserAccountSuccess, state => ({
    ...state,
    isLoading: false,
    isError: false,
    user: initialState.user,
    userVerify: initialState.userVerify,
    userLoading: false,
    userLoaded: false,
  })),

  on(actions.authDeleteUserAccountFail, state => ({
    ...state,
    isLoading: false,
    isError: true,
  })),

  on(actions.authSendForgotPasswordStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.authSendForgotPasswordSuccess, state => ({
    ...state,
    isLoading: false,
    isError: false,
  })),

  on(actions.authSendForgotPasswordFail, state => ({
    ...state,
    isLoading: false,
    isError: true,
  })),

  on(actions.authSendRegisterUserStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.authSendRegisterUserSuccess, state => ({
    ...state,
    isLoading: false,
    isError: false,
  })),

  on(actions.authSendRegisterUserFail, state => ({
    ...state,
    isLoading: false,
    isError: true,
  })),

  on(actions.authSendResetPasswordStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.authSendResetPasswordSuccess, state => ({
    ...state,
    isLoading: false,
    isError: false,
  })),

  on(actions.authSendResetPasswordFail, state => ({
    ...state,
    isLoading: false,
    isError: true,
  })),

  on(actions.authFetchUserSessionStart, state => ({
    ...state,
    userLoading: true,
    userLoaded: false,
  })),

  on(actions.authFetchUserSessionSuccess, (state, { user }) => ({
    ...state,
    user: { ...user },
    userLoading: false,
    userLoaded: true,
  })),

  on(actions.authFetchUserSessionFail, state => ({
    ...state,
    user: initialState.user,
    userLoading: false,
    userLoaded: false,
  })),

  on(actions.authFetchUserVerifyStart, state => ({
    ...state,
    userVerifyLoading: true,
    userVerifyLoaded: false,
  })),

  on(actions.authFetchUserVerifySuccess, (state, { payload }) => ({
    ...state,
    userVerify: { ...payload },
    userVerifyLoading: false,
    userVerifyLoaded: true,
  })),

  on(actions.authFetchUserVerifyFail, state => ({
    ...state,
    userVerify: initialState.userVerify,
    userVerifyLoading: false,
    userVerifyLoaded: false,
  })),
);
