import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FetchConstantResponse } from './constants.types';

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  constructor(private http: HttpClient) {}

  fetchList(): Observable<FetchConstantResponse> {
    return this.http.get<FetchConstantResponse>(`${environment.endpointApiTwo}/v2/constants/investor`, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
}
