import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';

import { App } from '@capacitor/app';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';

import { Store } from '@ngrx/store';

import { Subject, take, takeUntil } from 'rxjs';

import { AuthService } from '@views/misc/auth/auth.service';

import { SquirrelLogoComponent } from '@components/logo/squirrel/squirrel.component';

import { CapacitorPlatformTypes } from '@core/capacitor/capacitor.types';
import { selectFormErrorsMfaInProgress } from '@core/utilities/networkLayer/interceptor.selector';

@Component({
  selector: 'app-privacy-screen',
  imports: [SquirrelLogoComponent],
  template: `@if (showPrivacyScreen) {
    <div class="privacy-screen">
      <app-squirrel-logo class="squirrel-logo" />
    </div>
  }`,
  styleUrls: ['./privacy-screen.component.scss'],
})
export class PrivacyScreenComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  appResumeListener: PluginListenerHandle;
  appPauseListener: PluginListenerHandle;
  appStateChangeListener: PluginListenerHandle;
  showPrivacyScreen = false;
  constructor(
    private store: Store,
    public authService: AuthService,
    private ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    this.androidPrivacyListeners();
    this.iosPrivacyListeners();
  }

  async androidPrivacyListeners(): Promise<void> {
    // Android only works using the pause and resume listeners
    if (Capacitor.getPlatform() === CapacitorPlatformTypes.Android) {
      this.appPauseListener = await App.addListener('pause', () => {
        this.ngZone.run(() => {
          this.showPrivacyScreen = true;
        });
      });
      this.appResumeListener = await App.addListener('resume', () => {
        this.store
          .select(selectFormErrorsMfaInProgress)
          .pipe(takeUntil(this.destroy$), take(1))
          .subscribe(mfaInProgress => {
            // do not make any api calls if MFA is in progress this will break the MFA step
            if (mfaInProgress === false) {
              this.authService.getCheckAuthTokens(() => {
                // check to see if tokens are still valid this way we can log the user out before the privacy screen is hidden
                this.ngZone.run(() => {
                  this.showPrivacyScreen = false;
                });
              });
            } else {
              this.ngZone.run(() => {
                this.showPrivacyScreen = false;
              });
            }
          });
      });
    }
  }

  async iosPrivacyListeners(): Promise<void> {
    // Ios only works using appStateChange listeners
    if (Capacitor.getPlatform() === CapacitorPlatformTypes.Ios) {
      this.appStateChangeListener = await App.addListener('appStateChange', ({ isActive }) => {
        if (isActive) {
          this.store
            .select(selectFormErrorsMfaInProgress)
            .pipe(takeUntil(this.destroy$), take(1))
            .subscribe(mfaInProgress => {
              // do not make any api calls if MFA is in progress this will break the MFA step
              if (mfaInProgress === false) {
                this.authService.getCheckAuthTokens(() => {
                  // check to see if tokens are still valid this way we can log the user out before the privacy screen is hidden
                  this.ngZone.run(() => {
                    this.showPrivacyScreen = false;
                  });
                });
              } else {
                this.ngZone.run(() => {
                  this.showPrivacyScreen = false;
                });
              }
            });
        } else {
          this.ngZone.run(() => {
            this.showPrivacyScreen = true;
          });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if (this.appStateChangeListener !== undefined) {
      // remove resume event listner
      this.appStateChangeListener.remove();
    }
    if (this.appResumeListener !== undefined) {
      // remove resume event listner
      this.appResumeListener.remove();
    }
    if (this.appPauseListener !== undefined) {
      // remove pause event listner
      this.appPauseListener.remove();
    }
  }
}
