import { Injectable } from '@angular/core';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { EMPTY, race, switchMap, take } from 'rxjs';

import * as actions from './enums.actions';
import { EnumsState } from './enums.types';

@Injectable({ providedIn: 'root' })
export class EnumResolver {
  constructor(
    private store: Store<{ enum: EnumsState }>,
    private actions$: Actions,
  ) {}

  resolve() {
    this.store.dispatch(actions.enumsFetchListStart());

    const responseSuccess = this.actions$.pipe(ofType(actions.enumsFetchListSuccess));

    const responseError = this.actions$.pipe(
      ofType(actions.enumsFetchListFail),

      // Force the navigation to cancel and stay on the original route if the
      // async request failed.
      switchMap(() => EMPTY),
    );

    return race(responseSuccess, responseError).pipe(take(1));
  }
}
