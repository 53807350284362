import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from '@views/misc/auth/auth.service';

import { BankAccountsResponse } from './bank-accounts.types';

@Injectable({
  providedIn: 'root',
})
export class BankAccountsService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  fetchList(): Observable<BankAccountsResponse> {
    return this.http.get<BankAccountsResponse>(`${environment.endpointApiOne}/v1/bank-accounts`, {
      headers: this.authService.createAuthHeaders(),
      params: { pageIndex: 0, pageSize: 100 },
    });
  }
}
