import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, of, switchMap } from 'rxjs';

import { BorrowerDashboardService } from '@views/borrower/borrower.service';

import { SnackBarService } from '@components/snackbar/snackbar.service';

import { AuthService } from '../auth/auth.service';
import * as actions from './constants.actions';
import { ConstantsService } from './constants.service';

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private constantsService: ConstantsService,
    private borrowerService: BorrowerDashboardService,
    private snackBarService: SnackBarService,
    private authService: AuthService,
  ) {}

  fetchInvestorConstants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.constantsFetchInvestorStart),
      switchMap(() => {
        return this.constantsService.fetchList().pipe(
          map(payload => {
            return actions.constantsFetchInvestorSuccess({ payload });
          }),
          catchError(() => {
            this.snackBarService.setError('Sorry, there has been an issue fetching your page');
            return of(actions.constantsFetchInvestorFail());
          }),
        );
      }),
    ),
  );

  fetchLBorrowerConstants$ = createEffect(() =>
    this.authService.createGenericRequest({
      actions$: this.actions$,
      makeRequest: () => this.borrowerService.getBorrowerDashboard(),
      errorMessage: 'Sorry, there has been an issue fetching your page',
      startAction: actions.constantsFetchBorrowerStart,
      successAction: actions.constantsFetchBorrowerSuccess,
      failAction: actions.constantsFetchBorrowerFail,
    }),
  );
}
