import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from '@views/misc/auth/auth.service';

import {
  BrochureStatisticsGetResponse,
  ContentfulMarketplaceGetResponse,
  StatisticsGetResponse,
} from './statistics.types';

@Injectable({
  providedIn: 'root',
})
export class BrochureStatisticsService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  fetchStatisticsBrochure(): Observable<BrochureStatisticsGetResponse> {
    return this.http.get<BrochureStatisticsGetResponse>(`${environment.endpointApiTwo}/v2/statistics/brochure`, {
      headers: this.authService.createAuthHeaders(),
    });
  }

  fetchStatistics(): Observable<StatisticsGetResponse> {
    return this.http.get<StatisticsGetResponse>(`${environment.endpointApiOne}/v1/statistics`, {
      headers: this.authService.createAuthHeaders(),
    });
  }

  fetchMarketplaceCustomValues(): Observable<ContentfulMarketplaceGetResponse> {
    return this.http.get<ContentfulMarketplaceGetResponse>(
      `${environment.endpointApiOne}/v1/content/marketplaceStatistics/marketplace`,
    );
  }
}
