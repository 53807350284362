import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { LegalCopyFetchRequest, LegalCopyFetchResponse } from './legal-copy.types';

@Injectable({
  providedIn: 'root',
})
export class LegalCopyService {
  constructor(private http: HttpClient) {}
  fetchLegalCopy(payload: LegalCopyFetchRequest): Observable<LegalCopyFetchResponse> {
    const { id, contentTypeId } = payload;
    return this.http.get<LegalCopyFetchResponse>(`${environment.endpointApiOne}/v1/content/${contentTypeId}/${id}`);
  }
}
