import { createReducer, on } from '@ngrx/store';

import * as actions from './constants.actions';
import { ConstantsState } from './constants.types';

const initialState: ConstantsState = {
  items: null,
  borrower: null,
  isLoading: false,
  isError: false,
};

export const reducer = createReducer(
  initialState,

  on(actions.constantsFetchBorrowerStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.constantsFetchInvestorStart, state => ({
    ...state,
    isLoading: true,
  })),

  on(actions.constantsFetchInvestorSuccess, (state, { payload }) => ({
    ...state,
    items: {
      ...payload,
      countries: payload.countries
        // The payload also comes with an empty value with a `- Select -` we do not need this.
        .filter(({ value }) => value),
    },
    isLoading: false,
    isError: false,
  })),

  on(actions.constantsFetchInvestorFail, state => ({
    ...state,
    isLoading: false,
    isError: true,
  })),

  on(actions.constantsFetchBorrowerSuccess, (state, { payload }) => ({
    ...state,
    borrower: {
      squirrelMoneyBorrowerBankAccount: payload.squirrelMoneyBorrowerBankAccount,
    },
    isLoading: false,
    isError: false,
  })),

  on(actions.constantsFetchBorrowerFail, state => ({
    ...state,
    isLoading: false,
    isError: true,
  })),
);
