import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, switchMap } from 'rxjs';

import { SnackBarService } from '@components/snackbar/snackbar.service';

import * as actions from './notification-banners.actions';
import { NotificationBannersService } from './notification-banners.service';

@Injectable()
export class Effects {
  constructor(
    private actions$: Actions,
    private investorNotificationsService: NotificationBannersService,
    private snackBarService: SnackBarService,
  ) {}

  fetchNotificationBanners$ = createEffect(() =>
    // Doesn't use network layer as does not need authorisation
    this.actions$.pipe(
      ofType(actions.notificationBannersFetchStart),
      switchMap(() => {
        return this.investorNotificationsService.fetchNotifications().pipe(
          map(payload => {
            return actions.notificationBannersFetchSuccess({ payload });
          }),
          catchError(err => {
            const errorMessage =
              err?.error?.errorMessage?.length > 0
                ? err.error.errorMessage
                : 'Sorry, there has been an error fetching the notifications';

            this.snackBarService.setError(errorMessage);
            return [actions.notificationBannersFetchFail()];
          }),
        );
      }),
    ),
  );
}
