import { environment } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from '@views/misc/auth/auth.service';

import { UserMfaPostRequest, UserMfaResponse } from './security.types';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  getUserMfa(): Observable<UserMfaResponse> {
    return this.http.get<UserMfaResponse>(`${environment.endpointApiOne}/v1/users/mfa`, {
      headers: this.authService.createAuthHeaders(),
    });
  }

  postUserMfa(payload: UserMfaPostRequest): Observable<UserMfaResponse> {
    return this.http.post<UserMfaResponse>(`${environment.endpointApiOne}/v1/users/mfa`, payload, {
      headers: this.authService.createAuthHeaders(),
    });
  }

  postMfaManageUrl(): Observable<UserMfaResponse> {
    return this.http.post<UserMfaResponse>(
      `${environment.endpointApiOne}/v1/users/mfa/authenticators`,
      {},
      {
        headers: this.authService.createAuthHeaders(),
      },
    );
  }
}
